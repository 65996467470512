<template>
  <div class="padding-page">
    <!-- <div
      class="no-data bg-white lh-22"
      v-show="!loading && dataList.length == 0"
    >
      <img src="@img/no-data.png" alt="" />
      暂无数据~
    </div>
    <div
      class="company bg-white"
      v-for="company in dataList"
      :key="company.taxpayerCode"
    >
      <div class="name lh-22">{{ company.asName }}</div>
    </div> -->

    <van-pull-refresh style="overflow: auto" v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
      >
        <template v-slot:finished>
          <div class="no-data bg-white lh-22" v-if="dataList.length == 0">
            <img src="@img/no-data.png" alt="" />
            暂无数据~
          </div>
          <div v-else></div>
        </template>
        <div class="invoice-list">
          <div
            class="company bg-white"
            v-for="company in dataList"
            :key="company.taxpayerCode"
          >
            <div class="name">{{ company.asName }}</div>
            <div class="account-set" :class="company.type.toLowerCase()">
              {{
                company.typeDescription && company.typeDescription.length > 5
                  ? company.typeDescription.slice(0, 3)
                  : company.typeDescription
              }}
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import axios from "axios";
import { getAccountSets } from "../../../http/api";
export default {
  name: "Company",

  data() {
    return {
      dataList: [],
      loading: false,
      error: false,
      finished: false,
      refreshing: false,
      source: null,
    };
  },

  methods: {
    onLoad() {
      let this_ = this;
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
        this.cancelRequest();
      }
      getAccountSets({
        cancelToken: new axios.CancelToken(function executor(c) {
          this_.source = c;
        }),
      })
        .then((res) => {
          this.dataList.push(...res.data);
          this.loading = false;
          this.finished = true;
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            console.log("Rquest canceled", err.message); //请求如果被取消，这里是返回取消的message
            return;
          }
          this.error = true;
        });
    },

    cancelRequest() {
      if (typeof this.source === "function") {
        this.source("cancel request");
      }
    },

    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.error = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
  },
};
</script>

<style lang="less" scoped>
.company {
  display: flex;
  align-items: baseline;
  padding: 16px 16px 22px;
  margin-bottom: 16px;
  border-radius: 4px;
  position: relative;
  .name {
    height: 22px;
    display: flex;
    align-items: center;
    word-break: break-all;
    font-weight: 600;
  }
}
.van-pull-refresh {
  height: 100%;
}
.bottom-btn button {
  font-size: 16px;
  background-color: #ffffff;
}
.account-set {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 20px;
  padding: 0 6px;
  font-size: 12px;
  // margin-top: -6px;
  margin: 1px 0 1px 10px;
  border-radius: 2px;
  color: #ffffff;
  &.erp {
    width: 38px;
    color: transparent;
    background: url("~@img/erp.png") center / 100% 100% no-repeat;
  }
  &.acc {
    background: #06c05f;
  }
  &.acc_pro {
    width: 68px;
    color: transparent;
    background: url("~@img/acc-pro.png") center / 100% 100% no-repeat;
  }
  &.aa {
    background: #5059e9;
  }
  &.aa_pro {
    width: 68px;
    color: transparent;
    background: url("~@img/aa-pro.png") center / 100% 100% no-repeat;
  }
}
</style>
